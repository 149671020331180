<template>
  <basic-page title="Aloiteboksi">
    <v-row class="fill-height">
      <v-col cols="12" lg="12" xl="12">
        <v-row class="mb-5">
          <v-col cols="12" md="12">
            <v-card>
              <v-card-title>
                Aloitteet
                <v-spacer />
              </v-card-title>
              <v-card-subtitle>Näet tässä vain omat aloitteesi</v-card-subtitle>
              <v-divider />
              <v-card-text>
                <v-data-table
                  :items="filteredAloitteet"
                  :headers="headers"
                  :loading="loadingAloitteet"
                >
                  <template v-slot:item.description="{ item }">
                    <div class="text-pre-wrap">{{ item.description }}</div>
                  </template>
                  <template v-slot:item.created="{ item }">
                    {{ $formatDateAndHourMinute(item.created) }}
                  </template>
                  <template v-slot:item.comment="{ item }">
                    {{ item.comment }}
                  </template>
                  <template v-slot:item.images="{ item }">
                    <v-row>
                      <template v-for="image in item.images">
                        <v-col cols="auto" :key="image.thumbnailURL">
                          <div class="mt-2">
                            <v-img
                              :src="image.originalURL"
                              @click="openImageDialog(image)"
                              max-height="100"
                              max-width="100"
                              contain
                            ></v-img>
                          </div>
                        </v-col>
                      </template>
                    </v-row>
                  </template>
                  <template v-slot:item.ready="{ item }">
                    <v-row align="center" justify="left" no-gutters>
                      <v-icon color="green" v-if="item.ready"
                        >mdi mdi-check-circle-outline</v-icon
                      >
                    </v-row>
                    <div
                      class="caption mt-1"
                      v-if="item.commentBy && item.ready"
                    >
                      {{ item.commentBy ? item.commentBy.user : "" }}
                    </div>
                    <div class="caption" v-if="item.commentAt && item.ready">
                      {{ $formatDateAndHourMinute(item.commentAt) }}
                    </div>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      v-if="$isHPJ"
                      icon
                      @click="openAloite(item)"
                      :disabled="loadingAloitteet"
                    >
                      <v-icon>mdi mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title>Tee aloite</v-card-title>
              <v-card-subtitle
                >Voit luoda aloitteen tai ilmoittaa kehitysehdotuksen. Aloite
                menee tiedoksi hallituksen puheenjohtajalle (Joni
                Leino).</v-card-subtitle
              >
              <v-divider />

              <v-card-text>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-textarea
                      v-model="aloite.description"
                      label="Aloitteen kuvaus"
                      auto-grow
                      outlined
                      hide-details
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <file-upload
                      ref="fileupload"
                      :key="fileUploadKey"
                      accepted-file-types="image/*"
                      label="Paina tästä lisätäksesi kuvia. Voit lisätä 3 kuvaa. "
                      :max-files="3"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" v-if="aloite.createdBy">
                    <v-text-field
                      v-model="aloite.createdBy.user"
                      label="Aloitteen tekijä"
                      outlined
                      disabled
                      hide-details
                      :rules="[(v) => !!v || 'Aloitteen tekijä vaaditaan']"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-spacer />
                <v-btn @click="resetAloite" text>Peruuta</v-btn>
                <v-btn
                  color="primary"
                  @click="saveAloite"
                  :loading="loadingSave"
                  >Tallenna</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-dialog v-model="aloiteEditModal" persistent max-width="800">
        <v-card v-if="aloiteEditObject">
          <v-card-title>
            <v-row no-gutters
              >Aloite
              <v-spacer />
              <v-btn @click="aloiteEditModal = false" small icon
                ><v-icon>mdi mdi-close</v-icon></v-btn
              >
            </v-row></v-card-title
          >
          <v-divider />
          <v-card-text v-if="aloiteEditObject" class="mt-5">
            <v-row>
              <v-col cols="12" md="12">
                <v-textarea
                  v-model="aloiteEditObject.description"
                  label="Aloitteen kuvaus"
                  auto-grow
                  outlined
                  disabled
                  hide-details
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="12"
                v-if="aloiteEditObject.createdBy && aloiteEditObject.createdBy"
              >
                <v-text-field
                  v-model="aloiteEditObject.createdBy.user"
                  label="Aloitteen tekijä"
                  outlined
                  disabled
                  hide-details
                  :rules="[(v) => !!v || 'Aloitteen tekijä vaaditaan']"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12">
                <v-row>
                  <template v-for="image in aloiteEditObject.images">
                    <v-col cols="4" :key="image.thumbnailURL">
                      <div class="mt-2">
                        <v-img
                          :src="image.originalURL"
                          @click="openImageDialog(image)"
                          max-height="200"
                          contain
                        ></v-img>
                      </div>
                    </v-col>
                  </template>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-subtitle class="mt-5 text-subtitle-1"
            >Kommentointi</v-card-subtitle
          >
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12">
                <v-textarea
                  v-model="aloiteEditObject.comment"
                  label="Kommentoi aloitetta"
                  auto-grow
                  outlined
                  hide-details
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-switch
                  class="mt-0"
                  dense
                  hide-details
                  color="success"
                  v-model="aloiteEditObject.ready"
                  label="Huomioitu?"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <!--<v-btn @click="deleteAloite" color="error" :loading="loadingDelete"
              >Poista</v-btn
            >-->
            <v-btn text @click="aloiteEditModal = false">Sulje</v-btn>
            <v-btn color="primary" @click="saveAloite" :loading="loadingSave"
              >Tallenna</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <picture-dialog v-model="selectedPicture" :thumbnail="selectedThumbnail" />
  </basic-page>
</template>

<script>
import BasicPage from "@/components/BasicPage";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { aloitteetStorage, firestore as db } from "@/plugins/firebase.app";
import { debounce, orderBy } from "lodash";
import dayjs from "dayjs";
import FileUpload from "@/components/FileUpload.vue";
import { getDownloadURL, uploadBytes, ref } from "firebase/storage";
import { ref as VueRef } from "vue";
import { readAndCompressImage } from "browser-image-resizer";
import PictureDialog from "./PictureDialog.vue";

const aloiteTable = "aloitteet";

export default {
  name: "Aloiteboksi",
  components: { BasicPage, FileUpload, PictureDialog },
  data: () => {
    return {
      loading: false,
      loadingSave: false,
      loadingDelete: false,
      loadingAloitteet: false,
      aloiteEditModal: false,
      aloiteEditObject: null,
      selectedPicture: null,
      selectedThumbnail: null,
      locations: [],
      equipments: [],
      aloitteet: [],
      equipmentTypes: [],
      showOld: false,
      search: "",
      dSearch: "",
      debouncedSearch: null,
      fileUploadKey: `k-${Date.now()}`,

      aloite: {
        id: null,
        description: null,
        ready: false,
        createdBy: null,
        created: null,
        updated: null,
        deletedAt: null,
        deleted: false,
        comment: null,
        commentAt: null,
        commentBy: null,
      },
    };
  },
  watch: {
    search() {
      this.debouncedSearch();
    },
  },
  computed: {
    headers() {
      let h = [
        { text: "Aloite", value: "description" },
        { text: "Tekijä", value: "createdBy.user" },
        { text: "Luotu", value: "created" },
        { text: "Kuvat", value: "images" },
        { text: "Kommentti", value: "comment" },
        { text: "Huomioitu", value: "ready" },
      ];
      if (this.$isHPJ) {
        h.push({ text: "", value: "actions", width: 100 });
      }

      return h;
    },

    filteredAloitteet() {
      const now = dayjs().startOf("day");
      let aloitteet = orderBy(
        this.aloitteet.map((l) => {
          return {
            ...l,
          };
        }),
        ["created"],
        ["desc"]
      );
      return aloitteet;
    },
  },
  methods: {
    async fetchAloitteet() {
      this.loadingAloitteet = true;
      try {
        const snapshot = await getDocs(
          this.$isHPJ
            ? query(collection(db, aloiteTable), where("deleted", "==", false))
            : query(
                collection(db, aloiteTable),
                where("createdBy.uid", "==", this.$userDBContext.uid),
                where("deleted", "==", false)
              )
        );
        this.aloitteet = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
      } catch (e) {
        console.error(e);
        this.$toast("Aloitteiden haku epäonnistui", { type: "error" });
      }
      this.loadingAloitteet = false;
    },

    async openAloite(aloite) {
      this.aloiteEditModal = true;
      this.aloiteEditObject = {
        ...aloite,
        commentBy: this.$userDBContext,
      };
      this.selectedPicture = null;
      this.selectedThumbnail = null;
    },
    openImageDialog(image) {
      this.selectedPicture = image.originalURL;
      this.selectedThumbnail = image.thumbnailURL;
    },
    async uploadPicture(file, aloiteId) {
      if (!file) return "";
      if (!aloiteId) {
        this.$toast("Aloitteen ID on pakollinen", { type: "error" });
        return;
      }
      const date = Date.now();
      const storage = aloitteetStorage;
      //const originalFilePath = `aloite-tiedostot/${aloiteId}/${date}-${file.name}`;
      //const thumbnailFilePath = `aloite-tiedostot/${aloiteId}/${date}-thumbnail-${file.name}`;
      const originalFilePath = `aloite-tiedostot/${aloiteId}/${date}-${file.name}`;
      const thumbnailFilePath = `aloite-tiedostot/${aloiteId}/${date}-thumbnail-${file.name}`;

      // Create a thumbnail of the image
      //const thumbnail = await this.$createThumbnail(file);
      //const picture = await this.$createPicture(file);

      const thumbnail = await readAndCompressImage(file, {
        quality: 0.7,
        maxWidth: 150,
        maxHeight: 150,
        autoRotate: true,
        debug: true,
      });
      const picture = await readAndCompressImage(file, {
        quality: 0.7,
        maxWidth: 1920,
        maxHeight: 1080,
        autoRotate: true,
        debug: true,
      });

      // Upload original image to Firebase Storage
      const originalStorageRef = ref(storage, originalFilePath);
      await uploadBytes(originalStorageRef, picture);

      // Upload thumbnail image to Firebase Storage
      const thumbnailStorageRef = ref(storage, thumbnailFilePath);
      await uploadBytes(thumbnailStorageRef, thumbnail);

      // Get download URLs for both images
      const originalDownloadURL = await getDownloadURL(originalStorageRef);
      const thumbnailDownloadURL = await getDownloadURL(thumbnailStorageRef);

      // Return the URLs or do whatever is necessary
      return {
        originalURL: originalDownloadURL,
        thumbnailURL: thumbnailDownloadURL,
      };
    },
    async saveAloite() {
      // eslint-disable-next-line no-undef
      const aloite = structuredClone({
        ...(this.aloiteEditModal ? this.aloiteEditObject : this.aloite),
      });

      if (!aloite.description) {
        if (!aloite.description) {
          this.$toast("Aloitteen kuvaus ei voi olla tyhjä", { type: "error" });
        }
        //this.$toast("Täytä kaikki kentät.", { type: "error" });
        return;
      }
      this.loadingSave = true;
      try {
        // eslint-disable-next-line no-undef
        if (!aloite.id) {
          aloite.createdBy = this.$userDBContext;
          aloite.created = dayjs().toISOString();
          aloite.deleted = false;
        }
        if (this.aloiteEditModal) {
          aloite.commentAt = dayjs().toISOString();
        }

        console.log("Saving aloite", this.aloite);

        let docRef = null;
        const existsInDatabase = aloite.id
          ? (await getDoc(doc(db, aloiteTable, aloite.id))).exists()
          : false;
        if (!existsInDatabase) {
          console.log("Adding new aloite", aloite);
          docRef = await addDoc(collection(db, aloiteTable), aloite);
        } else {
          aloite.updated = dayjs().toISOString();
          docRef = doc(db, aloiteTable, aloite.id);
          await updateDoc(docRef, {
            ...aloite,
            deleted: false,
          });
        }
        // Get doc with data
        const aloiteId = (await getDoc(docRef)).id;
        aloite.id = aloiteId;
        if (!this.aloiteEditModal) {
          const files = await this.$refs.fileupload?.getRawFiles();

          if (files && files.length > 0) {
            const urls = await Promise.all(
              files.map(async (file) => {
                return await this.uploadPicture(file, aloite.id);
              })
            );
            await updateDoc(docRef, {
              ...aloite,
              images: urls,
            });
          }
        }

        this.$toast("Aloite tallennettu", { type: "success" });

        this.resetAloite();
        if (this.aloiteEditModal) {
          this.aloiteEditModal = false;
        }
        await this.fetchAloitteet();
      } catch (e) {
        console.error(e);
        this.$toast("Aloitteen tallennus epäonnistui", { type: "error" });
      }
      this.loadingSave = false;
    },
    async deleteAloite() {
      if (!this.aloite.id) {
        return;
      }
      this.loadingDelete = true;
      try {
        const aloite = { ...this.aloite };
        aloite.deleted = true;
        aloite.deletedAt = dayjs().toISOString();
        await updateDoc(doc(db, aloiteTable, aloite.id), aloite);
        this.resetAloite();
        await this.fetchAloitteet();
        0;
      } catch (e) {
        console.error(e);
        this.$toast("Aloitteen poisto epäonnistui", { type: "error" });
      }
      this.loadingDelete = false;
    },
    resetAloite() {
      this.fileUploadKey = `k-${Date.now()}`;
      this.aloite = {
        id: null,
        description: null,
        ready: false,
        createdBy: { ...this.$userDBContext },
        created: null,
        updated: null,
        deletedAt: null,
        deleted: false,
        comment: null,
        commentAt: null,
        commentBy: null,
      };
    },
  },
  mounted() {
    this.fetchAloitteet();
    if (this.aloite) {
      this.resetAloite();
    }
    this.debouncedSearch = debounce(() => (this.dSearch = this.search), 500);
  },
};
</script>

<style scoped lang="scss"></style>
