import Vue from "vue";
import VueRouter from "vue-router";
import Vaaratilannehavainto from "@/views/Vaaratilannehavainto";
import Ilmoitustaulu from "@/views/Ilmoitustaulu";
import Login from "@/views/Login";
import Harjoitukset from "@/views/Harjoitukset";
import Ilmoitus from "@/views/Ilmoitus";
import store from "@/store/index";
import Digivihko from "@/views/Digivihko";
import Digivihot from "@/views/Digivihot";
import Henkilot from "@/views/Henkilot";
import Kayttajat from "@/views/Kayttajat";
import Raportointi from "@/views/Raportointi";
import Leirikoski from "@/views/Leirikoski";
import Kalustonhuolto from "@/views/Kalustonhuolto.vue";
import Tiedostopankki from "@/views/Tiedostopankki.vue";
import IlmoitustauluHallitus from "@/views/IlmoitustauluHallitus.vue";
import IlmoitusHallitus from "@/views/IlmoitusHallitus.vue";
import TehtavaMallitHallitus from "@/views/TehtavaMallitHallitus.vue";
import KalustoEditView from "@/views/KalustoEditView.vue";
import * as Sentry from "@sentry/vue";
import Tehtavat from "@/views/Tehtavat.vue";
import Lainaus from "@/views/Lainaus.vue";
import Aloite from "@/views/Aloite.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/ilmoitustaulu",
    nav: false,
  },
  {
    path: "/ilmoitustaulu",
    name: "Ilmoitustaulu",
    label: "Ilmoitustaulu",
    component: Ilmoitustaulu,
  },
  {
    path: "/hallitus",
    name: "Hallitus",
    label: "Hallitus",
    component: IlmoitustauluHallitus,
    roles: ["hallitus"],
  },
  {
    path: "/login",
    name: "Login",
    label: "Sisäänkirjautuminen",
    component: Login,
    nav: false,
  },
  {
    path: "/harjoitukset",
    name: "Harjoitukset",
    label: "Harjoitukset",
    component: Harjoitukset,
    roles: ["user", "admin"],
  },
  {
    path: "/turvallisuusilmoitus",
    name: "Turvallisuusilmoitus",
    label: "Turvallisuusilmoitus",
    component: Vaaratilannehavainto,
  },
  {
    path: "/tiedostopankki",
    name: "Tiedostopankki",
    label: "Tiedostopankki",
    component: Tiedostopankki,
    roles: ["user"],
  },
  {
    path: "/ilmoitustaulu/ilmoitus/",
    name: "Ilmoitus",
    label: "Ilmoitus",
    component: Ilmoitus,
    nav: false,
  },
  {
    path: "/ilmoitustaulu/ilmoitus/:id",
    name: "IlmoitusById",
    component: Ilmoitus,
    nav: false,
  },
  {
    path: "/lainaus",
    name: "Lainaus",
    label: "Lainaus",
    component: Lainaus,
    roles: ["user", "naisosasto"],
  },
  {
    path: "/kalusto",
    name: "Kalusto",
    label: "Kalusto",
    component: Kalustonhuolto,
    roles: ["user"],
  },
  {
    path: "/kalusto/:id",
    name: "KalustoById",
    component: KalustoEditView,
    nav: false,
  },
  {
    path: "/leirikoski",
    name: "Leirikoski",
    label: "Leirikoski",
    component: Leirikoski,
    roles: ["user", "naisosasto"],
  },
  {
    path: "/tehtavat",
    name: "Tehtävät",
    label: "Tehtävät",
    component: Tehtavat,
    nav: true,
    roles: ["user"],
  },
  {
    path: "/aloite",
    name: "Aloiteboksi",
    label: "Aloiteboksi",
    component: Aloite,
    roles: ["user", "naisosasto"],
  },
  {
    path: "/digivihot",
    name: "Digivihot",
    label: "Digivihot",
    component: Digivihot,
    roles: ["admin"],
  },

  {
    path: "/digivihot/d/:id",
    name: "DigivihkoById",
    component: Digivihko,
    nav: false,
  },
  {
    path: "/digivihot/d/",
    name: "Digivihko",
    label: "Digivihko",
    component: Digivihko,
    nav: false,
  },

  {
    path: "/henkilot",
    name: "Henkilöt",
    label: "Henkilöt",
    component: Henkilot,
    part: "system",
    roles: ["admin"],
  },
  {
    path: "/kayttajat",
    name: "Käyttäjät",
    label: "Käyttäjät",
    component: Kayttajat,
    part: "system",
    roles: ["admin"],
  },
  {
    path: "/raportointi",
    name: "Raportointi",
    label: "Raportointi",
    component: Raportointi,
    part: "system",
    roles: ["admin"],
  },
  {
    path: "/hallitus/ilmoitus/",
    name: "HallitusIlmoitus",
    label: "HallitusIlmoitus",
    component: IlmoitusHallitus,
    nav: false,
  },
  {
    path: "/hallitus/ilmoitus/:id",
    name: "HallitusIlmoitusById",
    component: IlmoitusHallitus,
    nav: false,
  },
  {
    path: "/hallitus/mallitehtavat",
    name: "HallitusMalliTehtavat",
    component: TehtavaMallitHallitus,
    nav: false,
  },
];
let prev = null;
const router = new VueRouter({
  routes,
});
if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    Vue,
    dsn: "https://47af7474a1e459e439f8d2cfec87e750@o4508513812545536.ingest.de.sentry.io/4508513816215632",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        // NOTE: This will disable built-in masking. Only use this if your site has no sensitive data, or if you've already set up other options for masking or blocking relevant data, such as 'ignore', 'block', 'mask' and 'maskFn'.
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/app.noormarkunvpk\.fi/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
router.beforeEach((to, from, next) => {
  if (to.name !== "Login" && !store?.state.user) {
    next({ name: "Login" });
  } else {
    next((vm) => {
      prev = from;
    });
  }
});
export { routes };
export default router;
