import Vue from "vue";
import dayjs from "dayjs";
import Toast from "vue-toastification"; // Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import { deleteToken, getMessaging } from "firebase/messaging";
import app from "@/plugins/firebase.app";

const toastOptions = {
  timeout: 2500,
};
Vue.use(Toast, toastOptions);

export default Vue.mixin({
  methods: {
    $capitalFirstLetter(s) {
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
    $formatDate(date) {
      if (date === null || date === undefined) return null;
      return dayjs(date).format("DD.MM.YYYY");
    },

    $formatDateAndHourMinute(date) {
      if (date === null || date === undefined) return null;
      if (dayjs(date).isValid()) {
        return dayjs(date).format("DD.MM.YYYY HH:mm");
      } else {
        return "Päivämäärä ei ole oikein";
      }
    },
    $formatWeek(date) {
      if (date === null) return null;
      return dayjs(date).format("WW/GGGG");
    },
    $formatWeekDayDateAndHourMinute(date) {
      return dayjs(date).format("dd DD.MM.YYYY HH:mm");
    },
    $formatWeekDayDate(date) {
      return dayjs(date).format("dd DD.MM.YYYY");
    },
    $parseDateAsHelsinki(date) {
      return dayjs.tz(date, "YYYY-MM-DD HH:mm", "Europe/Helsinki");
    },
    $formatWeekDay(date) {
      return dayjs(date).format("dd");
    },
    $formatMonthYear(date) {
      return dayjs(date).format("MMMM YYYY");
    },
    async $logout() {
      await this.$store.dispatch("logout");
      this.$router.push({ name: "Login" });
      try {
        const messaging = getMessaging(app);
        await deleteToken(messaging);
      } catch (e) {
        console.error(e);
      }
    },
    $id(item) {
      return item?.id;
    },
    $uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },
    $deleteConfirm(title) {
      return this.$confirm(title, {
        buttonTrueText: "Kyllä",
        buttonTrueColor: "primary",
        buttonFalseText: "Ei",
        buttonFalseColor: "error",
      });
    },
    $hasRole(role) {
      return this.$userInfo?.customAttributes?.roles.includes(role);
    },
    $hasRoleIn(roles) {
      if (!roles) return true;
      return roles.some((r) =>
        this.$userInfo?.customAttributes?.roles.includes(r)
      );
      //return this.$userInfo?.customAttributes?.roles.includes(role);
    },
    $createThumbnail(file) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        const reader = new FileReader();

        // When the file is read as a data URL, load it into the image element
        reader.onload = (event) => {
          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            // Resize the image (you can modify the width and height)
            const width = 150; // Thumbnail width
            const height = (img.height / img.width) * width; // Maintain aspect ratio
            canvas.width = width;
            canvas.height = height;

            // Draw the image onto the canvas
            ctx.drawImage(img, 0, 0, width, height);

            // Convert the canvas to a Blob (thumbnail file)
            canvas.toBlob((blob) => {
              if (blob) {
                resolve(blob); // Resolve with the thumbnail Blob
              } else {
                reject("Error generating thumbnail");
              }
            }, "image/jpeg"); // You can also use other image formats like 'image/png'
          };
          img.src = event.target.result; // Set image source to the file data URL
        };

        reader.onerror = (error) => reject(error);

        // Read the file as a data URL
        reader.readAsDataURL(file);
      });
    },
    $createPicture(file) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        const reader = new FileReader();

        // When the file is read as a data URL, load it into the image element
        reader.onload = (event) => {
          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            // Check if the image width is less than 2000 pixels
            if (img.width < 2000) {
              // If the image is smaller than 2000 pixels, use its original dimensions
              canvas.width = img.width;
              canvas.height = img.height;
              ctx.drawImage(img, 0, 0, img.width, img.height);
            } else {
              // Resize the image to a width of 2000 pixels while maintaining aspect ratio
              const width = 2000;
              const height = (img.height / img.width) * width;
              canvas.width = width;
              canvas.height = height;
              ctx.drawImage(img, 0, 0, width, height);
            }

            // Convert the canvas to a Blob (thumbnail file)
            canvas.toBlob((blob) => {
              if (blob) {
                resolve(blob); // Resolve with the thumbnail Blob
              } else {
                reject("Error generating thumbnail");
              }
            }, "image/jpeg"); // You can also use other image formats like 'image/png'
          };
          img.src = event.target.result; // Set image source to the file data URL
        };

        reader.onerror = (error) => reject(error);

        // Read the file as a data URL
        reader.readAsDataURL(file);
      });
    },
  },
  computed: {
    $pwa() {
      return (
        window.matchMedia("(display-mode: standalone)").matches ||
        window.navigator.standalone ||
        document.referrer.includes("android-app://")
      );
    },
    $iOS() {
      return (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    },
    $user() {
      return this.$store.state.user;
    },
    $userInfo() {
      const userInfo = this.$store.state.user?.reloadUserInfo;
      try {
        userInfo.customAttributes = JSON.parse(userInfo.customAttributes);
      } catch (e) {
        //.
      }
      return userInfo;
    },
    $qualifications() {
      return this.$userInfo.customAttributes?.qualifications || [];
    },
    $userDBContext() {
      return {
        email: this.$userInfo?.email,
        sub: this.$userInfo?.localId,
        user: this.$userInfo?.displayName,
      };
    },
    $isUser() {
      return this.$userInfo?.customAttributes?.roles.includes("user");
    },
    $isAdmin() {
      return this.$userInfo?.customAttributes?.roles.includes("admin");
    },
    $isHallitus() {
      return this.$userInfo?.customAttributes?.roles.includes("hallitus");
    },
    $isNaisosasto() {
      return this.$userInfo?.customAttributes?.roles.includes("naisosasto");
    },
    $isOnlyNaisosasto() {
      return this.$isNaisosasto && !this.$isAdmin && !this.$isUser;
    },
    $isMissingUserRole() {
      return (
        !this.$userInfo?.customAttributes?.roles ||
        (!this.$userInfo?.customAttributes?.roles?.includes("user") &&
          !this.$userInfo?.customAttributes?.roles?.includes("naisosasto"))
      );
    },
    $isSuperAdmin() {
      let emailList = [
        "jonirautiainen@gmail.com",
        "joni.leino88@gmail.com",
        "jarnojjoensuu@gmail.com",
        "heikkila.kai65@gmail.com",
        "janne.leinonen@outlook.com",
      ];
      return emailList.includes(this.$userInfo?.email);
    },
    $isHPJ() {
      let emailList = ["jonirautiainen@gmail.com", "joni.leino88@gmail.com"];
      return emailList.includes(this.$userInfo?.email);
    },
    $isTyosuojelutoimikunta() {
      let emailList = [
        "jarnojjoensuu@gmail.com",
        "heikkila.kai65@gmail.com",
        "jiiihelin76@gmail.com",
      ];
      return emailList.includes(this.$userInfo?.email);
    },
    $rules() {
      return {
        required: (value) => !!value || "Tämä kenttä ei voi olla tyhjä.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return (
            pattern.test(value) || "Oikean muotoinen sähköposti vaaditaan."
          );
        },
      };
    },
  },
});
