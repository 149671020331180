<template>
  <v-card flat>
    <v-tabs v-model="tab">
      <v-tab>Perustiedot</v-tab>
      <v-tab v-if="!equipment?._new">Huolto</v-tab>
    </v-tabs>
    <v-divider />

    <v-card-text v-if="loadingEquipment">
      <v-skeleton-loader type="card-heading, list-item, list-item, list-item" />
    </v-card-text>
    <v-tabs-items v-model="tab" v-if="!loadingEquipment">
      <v-tab-item>
        <v-card>
          <v-card-title class="pt-8"
            >{{ equipment ? `${equipment.name}` : "" }}
          </v-card-title>
          <v-card-subtitle>
            <v-row no-gutters>
              <v-col cols="auto">
                <div>{{ equipment.id }}</div>
                <div v-if="equipment.location">
                  {{ equipment.location }}
                  <v-btn
                    class="ml-2"
                    @click="openLocationDialog"
                    icon
                    x-small
                    v-if="editMode"
                    ><v-icon>mdi mdi-pencil</v-icon></v-btn
                  >
                </div>
                <div v-if="!equipment.location" class="mr-2">
                  Valitse sijainti
                  <v-btn
                    class="ml-2"
                    @click="openLocationDialog"
                    icon
                    x-small
                    v-if="editMode"
                    ><v-icon>mdi mdi-pencil</v-icon></v-btn
                  >
                </div>
              </v-col>

              <v-spacer />
              <v-btn @click="editMode = true" small text v-if="!editMode"
                ><v-icon>mdi mdi-pencil</v-icon> Muokkaa</v-btn
              >
            </v-row>
          </v-card-subtitle>
          <v-card-text v-if="!editMode" class="text-subtitle-1">
            <v-divider class="mb-4" />
            <v-row>
              <v-col cols="12" md="6">
                <strong>ID:</strong> {{ equipment.id }}
              </v-col>
              <v-col cols="12" md="6">
                <strong>Kaluston nimi:</strong> {{ equipment.name }}
              </v-col>
              <v-col cols="12" md="6">
                <strong>Tyyppi:</strong> {{ equipment.typeFullTree }}
              </v-col>
              <v-col cols="12" md="6" v-if="equipment.manufacturer">
                <strong>Valmistaja:</strong> {{ equipment.manufacturer }}
              </v-col>
              <v-col cols="12" md="6" v-if="equipment.purchaseDate">
                <strong>Ostopäivä / Vastaanottopäivä:</strong>
                {{ equipment.purchaseDate }}
              </v-col>
              <v-col cols="12" v-if="equipment.description">
                <strong>Kuvaus:</strong> {{ equipment.description }}
              </v-col>
              <v-col cols="12" v-if="equipment.owner">
                <strong>Omistaja:</strong> {{ equipment.owner }}
              </v-col>
              <v-col cols="12" v-if="equipment.notes">
                <strong>Muistiinpanot:</strong> {{ equipment.notes }}
              </v-col>
              <v-col cols="12">
                <strong>Lainattavissa:</strong>
                <v-icon v-if="!equipment.loanable" class="ml-2"
                  >mdi mdi-checkbox-blank-outline</v-icon
                >
                <v-icon class="ml-2" v-else
                  >mdi mdi-checkbox-marked-outline</v-icon
                >
              </v-col>
              <v-col cols="12">
                <v-divider class="mt-4 mb-3" />
              </v-col>

              <v-col cols="12">
                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                    class="mb-3"
                    :key="i"
                    v-for="(pic, i) in [
                      { picture: 'picture', thumbnail: 'pictureThumbnail' },
                      { picture: 'picture2', thumbnail: 'pictureThumbnail2' },
                      { picture: 'picture3', thumbnail: 'pictureThumbnail3' },
                    ]"
                  >
                    <template v-if="equipment[pic['picture']]">
                      <strong>Kuva {{ i + 1 }}:</strong>
                      <br />
                      <div class="mt-5" v-if="equipment[pic['picture']]">
                        <v-img
                          :src="equipment[pic['thumbnail']]"
                          max-height="200"
                          contain
                          @click="
                            () => {
                              selectedPicture = equipment[pic['picture']];
                              selectedThumbnail = equipment[pic['thumbnail']];
                            }
                          "
                        />
                      </div>
                    </template>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-if="editMode">
            <v-divider class="mb-4" />
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="ID"
                  v-model="equipment.id"
                  required
                  outlined
                  :disabled="!equipment._new"
                  :append-icon="
                    loadingGenerateId
                      ? 'mdi mdi-spin mdi-loading'
                      : 'mdi-cog-play-outline'
                  "
                  @click:append="() => generateId()"
                  append-outer-icon="mdi-qrcode-scan"
                  @click:append-outer="showQrCodeScanner = true"
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Kaluston nimi"
                  v-model="equipment.name"
                  required
                  outlined
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Tyyppi"
                  v-model="equipment.typeFullTree"
                  readonly
                  auto-grow
                  outlined
                  hide-details
                  @click="openTypeDialog"
                  append-icon="mdi-chevron-right"
                  @click:append="openTypeDialog"
                >
                </v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" lg="6">
                <v-text-field
                  label="Valmistaja"
                  v-model="equipment.manufacturer"
                  outlined
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  label="Ostopäivä / Vastaanottopäivä"
                  v-model="equipment.purchaseDate"
                  outlined
                  type="date"
                  hide-details
                ></v-text-field
              ></v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  label="Kuvaus"
                  v-model="equipment.description"
                  outlined
                  auto-grow
                  hide-details
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Omistaja"
                  v-model="equipment.owner"
                  outlined
                  hide-details
                  clearable
                ></v-text-field>
                <div class="mt-2" v-if="!equipment.owner">
                  <span class="mr-2">Esivalinnat:</span>
                  <v-chip
                    @click="equipment.owner = 'Noormarkun VPK'"
                    class="mr-1"
                    small
                    >Noormarkun VPK</v-chip
                  >
                  <v-chip
                    small
                    @click="
                      equipment.owner =
                        'Satakunnan Pelastuslaitos / Satakunnan hyvinvointialue'
                    "
                    >SatPel</v-chip
                  >
                </div>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Muistiinpanot"
                  v-model="equipment.notes"
                  outlined
                  auto-grow
                  hide-details
                ></v-textarea>
              </v-col>
              <v-col cols="6">
                <v-checkbox
                  label="Lainattavissa"
                  v-model="equipment.loanable"
                  outlined
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-divider class="mt-4 mb-3" />

            <v-row>
              <v-col
                cols="12"
                md="4"
                class="mb-3"
                :key="i"
                v-for="(pic, i) in [
                  { picture: 'picture', thumbnail: 'pictureThumbnail' },
                  { picture: 'picture2', thumbnail: 'pictureThumbnail2' },
                  { picture: 'picture3', thumbnail: 'pictureThumbnail3' },
                ]"
              >
                <strong>Kuva {{ i + 1 }}:</strong>
                <br />
                <v-row>
                  <v-col cols="auto">
                    <input
                      type="file"
                      :id="`pictureUpload${i + 1}`"
                      accept="image/*"
                      @change="(e) => onPictureSelected(e, i + 1)"
                      width="100"
                    />
                  </v-col>
                </v-row>

                <div class="mt-5" v-if="equipment[pic['picture']]">
                  <v-img
                    :src="equipment[pic['thumbnail']]"
                    max-height="200"
                    contain
                    @click="
                      () => {
                        selectedPicture = equipment[pic['picture']];
                        selectedThumbnail = equipment[pic['thumbnail']];
                      }
                    "
                  />
                </div>
                <v-row
                  class="mt-2"
                  v-if="equipment[pic['picture']]"
                  justify="center"
                >
                  <v-col cols="auto">
                    <v-btn
                      small
                      text
                      color="error"
                      @click="
                        () => {
                          equipment[pic['picture']] = null;
                          equipment[pic['thumbnail']] = null;
                        }
                      "
                      v-if="equipment[pic['picture']]"
                      ><v-icon small>mdi mdi-delete</v-icon> Poista kuva
                      {{ i + 1 }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card-text>
          <maaraaikaishuolto-view
            v-if="equipment.id"
            :key="equipment.id"
            :kalusto="equipment"
          ></maaraaikaishuolto-view>
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>

    <v-divider />
    <v-card-actions>
      <v-btn
        v-if="equipment.id && editMode && tab === 0"
        :loading="loadingDelete"
        color="error"
        @click="deleteEquipment"
        >Poista
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn text v-if="dialog" @click="$emit('close')">Sulje</v-btn>
      <v-btn
        v-if="editMode && tab === 0"
        color="primary"
        @click="saveEquipment"
        :loading="loadingEquipmentSave"
        >Tallenna
      </v-btn>
    </v-card-actions>
    <picture-dialog v-model="selectedPicture" :thumbnail="selectedThumbnail" />
    <kalusto-tyyppi-dialog
      ref="kalustoTyyppiDialog"
      @select="confirmTypeSelection"
    />
    <kalusto-sijainnit-dialog
      ref="kalustoSijainnitDialog"
      @select="confirmLocationSelection"
    />

    <qr-code-scanner-dialog
      @detect="onDetect"
      v-model="showQrCodeScanner"
      ref="qrCodeScannerDialog"
    />
  </v-card>
</template>
<script>
import MaaraaikaishuoltoView from "@/views/MaaraaikaishuoltoView.vue";
import { firestore as db, kalustoStorage } from "@/plugins/firebase.app";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  runTransaction,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import PictureDialog from "@/views/PictureDialog.vue";
import KalustoTyyppiDialog from "@/components/KalustoTyyppiDialog.vue";
import QrCodeScannerDialog from "@/views/QrCodeScannerDialog.vue";
import KalustoSijainnitDialog from "@/components/KalustoSijainnitDialog.vue";

const equipmentTable = "kalusto";
const equipmentTypesTable = "kalusto_tyypit";
export default {
  name: "kalusto-edit",
  components: {
    KalustoSijainnitDialog,
    QrCodeScannerDialog,
    KalustoTyyppiDialog,
    PictureDialog,
    MaaraaikaishuoltoView,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingEquipment: false,
      loadingEquipmentSave: false,
      loadingDelete: false,
      loadingGenerateId: false,
      equipmentTypes: [],
      selectedPicture: null,
      selectedThumbnail: null,
      showQrCodeScanner: false,
      editMode: false,
      equipment: {
        id: "",
        typeId: null,
        name: "",
        location: location._fullTree,
        locationId: location.id,
        description: "",
        notes: "",
        purchaseDate: "",
        manufacturer: "",
        picture: "",
        pictureFile1: null,
        pictureFile2: null,
        pictureFile3: null,
        deleted: null,
        typeFullTree: null,
        owner: null,
        _new: true,
        loanable: false,
      },
      tab: 0,
    };
  },
  emits: ["close"],
  computed: {
    equipmentTypeTree() {
      // Create map of types
      const map = new Map(
        this.equipmentTypes.map((type) => [
          type.id,
          { ...type, children: [], _fullTree: "" },
        ])
      );

      // Link children to their parents (if needed)
      for (const [id, node] of map.entries()) {
        if (node.parent && map.has(node.parent)) {
          map.get(node.parent).children.push(node);
        }
      }

      // Recursive function to build _fullTree
      function buildFullTree(id, map) {
        const node = map.get(id);
        if (!node) return "";

        // If _fullTree already computed, return it
        if (node._fullTree) {
          return node._fullTree;
        }

        // If no parent, _fullTree is just the node's name
        if (!node.parent) {
          node._fullTree = node.name;
          return node._fullTree;
        }

        // Otherwise, recursively get parent's _fullTree and append current node
        const parentFullTree = buildFullTree(node.parent, map);
        node._fullTree = `${parentFullTree} -> ${node.name}`;
        return node._fullTree;
      }

      // Populate _fullTree for all
      for (const [id] of map.entries()) {
        buildFullTree(id, map);
      }

      const tree = [];
      for (const [, node] of map.entries()) {
        tree.push(node);
      }
      return tree;
    },
  },
  methods: {
    async fetchEquipmentTypes() {
      const snapshot = await getDocs(
        query(
          collection(db, equipmentTypesTable),
          where("deleted", "==", false)
        )
      );
      this.equipmentTypes = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    },

    resetEquipment() {
      this.equipment = {
        id: "",
        typeId: null,
        name: "",
        location: null,
        locationId: null,
        description: "",
        notes: "",
        purchaseDate: "",
        manufacturer: "",
        picture: "",
        pictureFile1: null,
        pictureFile2: null,
        pictureFile3: null,
        deleted: null,
        typeFullTree: null,
        owner: null,
        _new: true,
        loanable: false,
      };
    },
    addEquipment(location) {
      this.resetEquipment();
      this.editMode = true;
      this.equipment.location = location._fullTree;
      this.equipment.locationId = location.id;
    },
    async editEquipment(id, fromQr = false) {
      this.resetEquipment();
      this.editMode = true;
      await this.fetchEquipment(id, fromQr);
    },
    async viewEquipment(id, fromQr = false) {
      this.resetEquipment();
      this.editMode = false;
      await this.fetchEquipment(id, fromQr);
    },

    async fetchEquipment(id, fromQr = false) {
      this.loadingEquipment = true;
      try {
        let _id = id?.toUpperCase();
        const snapshot = await getDoc(doc(db, equipmentTable, _id));
        if (snapshot.exists()) {
          this.equipment = {
            owner: null,
            pictureFile1: null,
            pictureFile2: null,
            pictureFile3: null,
            ...snapshot.data(),
            typeFullTree: null,
          };
          if (!this.equipmentTypeTree || !this.equipmentTypeTree.length) {
            await this.fetchEquipmentTypes();
          }
          const type = this.equipmentTypeTree.find(
            (t) => t.id === this.equipment.typeId
          );
          if (type) {
            this.equipment.typeFullTree = type._fullTree;
          } else {
            console.log(
              "type not found",
              this.equipmentTypeTree,
              this.equipment.typeId
            );
          }
        } else {
          this.$toast("Kalustoa ei löytynyt annetulla ID-koodilla " + id, {
            type: "error",
          });
        }
      } catch (e) {
        console.error(e);
        this.$toast("Kaluston haku epäonnistui", { type: "error" });
      }
      this.loadingEquipment = false;
    },
    async generateSequentialId() {
      const sequenceDoc = doc(db, "sequences", "kalustoId");
      try {
        const newId = await runTransaction(db, async (transaction) => {
          const docSnapshot = await transaction.get(sequenceDoc);

          if (!docSnapshot.exists()) {
            // Initialize the sequence if it doesn't exist
            transaction.set(sequenceDoc, { latest: 1 });
            return 1; // Return the first ID
          }

          // Increment the sequence
          const currentId = docSnapshot.data().latest;
          const nextId = currentId + 1;
          transaction.update(sequenceDoc, { latest: nextId });

          return nextId; // Return the new ID
        });

        return newId;
      } catch (error) {
        console.error("Error generating ID:", error);
        throw error;
      }
    },
    async generateId() {
      this.loadingGenerateId = true;
      try {
        const newId = await this.generateSequentialId();
        this.equipment.id = `NO51${String(newId)}`;
        try {
          const snapshot = await getDoc(doc(db, equipmentTable, newId));
          if (snapshot.exists()) {
            this.$toast("Kalusto ID on jo käytössä", { type: "error" });
            this.equipment.id = "";
          }
        } catch (e) {
          //console.error(e);
        }
      } catch (e) {
        console.error(e);
        this.$toast("ID:n generointi epäonnistui", { type: "error" });
      }
      this.loadingGenerateId = false;
    },
    onDetect(code) {
      this.equipment.id = code;
      this.showQrCodeScanner = false;
    },
    // Handle image file selection
    onPictureSelected(e, i) {
      const file = e.target.files[0];
      this.equipment[`pictureFile${i}`] = file;
    },
    async uploadEquipmentPicture(file) {
      if (!file) return "";
      if (!this.equipment.id) {
        this.$toast("Kaluston ID on pakollinen", { type: "error" });
        return;
      }
      const date = Date.now();
      const storage = kalustoStorage;
      const originalFilePath = `kalusto-kuvat/${this.equipment.id}/${date}-${file.name}`;
      const thumbnailFilePath = `kalusto-kuvat/${this.equipment.id}/${date}-thumbnail-${file.name}`;

      // Create a thumbnail of the image
      const thumbnail = await this.$createThumbnail(file);
      const picture = await this.$createPicture(file);

      // Upload original image to Firebase Storage
      const originalStorageRef = ref(storage, originalFilePath);
      await uploadBytes(originalStorageRef, picture);
      //await uploadBytes(originalStorageRef, file); // This will upload the original size image

      // Upload thumbnail image to Firebase Storage
      const thumbnailStorageRef = ref(storage, thumbnailFilePath);
      await uploadBytes(thumbnailStorageRef, thumbnail);

      // Get download URLs for both images
      const originalDownloadURL = await getDownloadURL(originalStorageRef);
      const thumbnailDownloadURL = await getDownloadURL(thumbnailStorageRef);

      // Return the URLs or do whatever is necessary
      return {
        originalURL: originalDownloadURL,
        thumbnailURL: thumbnailDownloadURL,
      };
    },

    // Save equipment
    async saveEquipment() {
      if (!this.equipment?.id?.trim()) {
        this.$toast("Kaluston ID on pakollinen", { type: "error" });
        return;
      }
      if (!this.equipment?.name?.trim()) {
        this.$toast("Kaluston nimi on pakollinen", { type: "error" });
        return;
      }
      this.loadingEquipmentSave = true;
      if (this.equipment._new) {
        try {
          const snapshot = await getDoc(
            doc(db, equipmentTable, this.equipment.id)
          );
          if (snapshot.exists()) {
            this.$toast("Kalusto ID on jo käytössä", { type: "error" });
            this.loadingEquipmentSave = false;
            return;
          }
        } catch (e) {
          // most likely not found
        }
      }

      try {
        const pictureURLs = await this.uploadEquipmentPicture(
          this.equipment.pictureFile1
        );
        const pictureURLs2 = await this.uploadEquipmentPicture(
          this.equipment.pictureFile2
        );
        const pictureURLs3 = await this.uploadEquipmentPicture(
          this.equipment.pictureFile3
        );

        const equipmentData = {
          id: this.equipment.id?.trim() || null,
          name: this.equipment.name?.trim() || null,
          location: this.equipment.location || null,
          locationId: this.equipment.locationId || null,
          description: this.equipment.description || null,
          notes: this.equipment.notes || null,
          typeId: this.equipment.typeId || null,
          purchaseDate: this.equipment.purchaseDate || null,
          manufacturer: this.equipment.manufacturer || null,
          owner: this.equipment.owner || null,
          picture: pictureURLs?.originalURL || this.equipment.picture || null,
          pictureThumbnail:
            pictureURLs?.thumbnailURL ||
            this.equipment.pictureThumbnail ||
            null,
          picture2:
            pictureURLs2?.originalURL || this.equipment.picture2 || null,
          pictureThumbnail2:
            pictureURLs2?.thumbnailURL ||
            this.equipment.pictureThumbnail2 ||
            null,
          picture3:
            pictureURLs3?.originalURL || this.equipment.picture3 || null,
          pictureThumbnail3:
            pictureURLs3?.thumbnailURL ||
            this.equipment.pictureThumbnail3 ||
            null,
          loanable: this.equipment.loanable || false,
          createdAt: this.equipment.createdAt || new Date().toISOString(),
          createdBy: this.$userDBContext,
          updatedAt: new Date().toISOString(),
          updatedBy: this.$userDBContext,
          deleted: false,
        };

        const foundInDatabase = (
          await getDocs(
            query(
              collection(db, equipmentTable),
              where("id", "==", equipmentData.id)
            )
          )
        ).docs.map((doc) => ({ id: doc.id, ...doc.data() }))?.[0];
        if (!foundInDatabase) {
          await setDoc(
            doc(db, equipmentTable, equipmentData.id),
            equipmentData
          );
        } else {
          await updateDoc(doc(db, equipmentTable, equipmentData.id), {
            ...foundInDatabase,
            ...equipmentData,
            deleted: false,
          });
        }

        this.$toast("Kalusto lisätty onnistuneesti!", { type: "success" });
        this.dialogEquipment = false;
        await this.editEquipment(equipmentData.id);
      } catch (e) {
        console.error(e);
        this.$toast("Kaluston tallennus epäonnistui", { type: "error" });
      }
      this.loadingEquipmentSave = false;
    },
    confirmTypeSelection(type) {
      if (type) {
        this.equipment.typeId = type.id;
        this.equipment.typeFullTree = type._fullTree;
      } else {
        this.equipment.typeId = null;
        this.equipment.typeFullTree = null;
      }
    },
    confirmLocationSelection(type) {
      if (type) {
        this.equipment.locationId = type.id;
        this.equipment.location = type._fullTree;
      } else {
        this.equipment.locationId = null;
        this.equipment.location = null;
      }
    },
    openTypeDialog() {
      this.$refs.kalustoTyyppiDialog.openTypeDialog();
      this.$refs.kalustoTyyppiDialog.selectTypeById(this.equipment.typeId);
    },
    openLocationDialog() {
      this.$refs.kalustoSijainnitDialog.openLocationDialog();
      this.$refs.kalustoSijainnitDialog.selectLocationById(
        this.equipment.typeId
      );
    },
    async deleteEquipment() {
      if (!(await this.$deleteConfirm("Haluatko varmasti poistaa kaluston?"))) {
        return;
      }
      this.loadingDelete = true;
      await updateDoc(doc(db, equipmentTable, this.equipment.id), {
        deleted: true,
      });

      this.loadingDelete = false;
      this.$emit("close");
    },
  },
  async mounted() {
    await this.fetchEquipmentTypes();
  },
};
</script>
