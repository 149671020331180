<template>
  <div style="max-width: 500px">
    <file-pond
      name="filepond"
      ref="pond"
      :label-idle="label"
      :allow-multiple="multiple"
      :accepted-file-types="acceptedFileTypes"
      :instant-upload="false"
      :max-files="maxFiles"
      @init="handleFilePondInit"
      @updatefiles="updateFiles"
    >
    </file-pond>
    <slot name="append" :has-files="hasFiles"> </slot>
  </div>
</template>

<script>
import { readAndCompressImage } from "browser-image-resizer";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";

const FilePond = vueFilePond(
  FilePondPluginImagePreview,
  FilePondPluginFileEncode,
  FilePondPluginFileValidateType,
  FilePondPluginImageExifOrientation
);

export default {
  name: "FileUpload",
  components: {
    FilePond,
  },
  emits: ["update:files"],
  props: {
    label: {
      default: `<div class='d-flex align-center'><div><i class='mdi mdi-cloud-upload-outline' style='font-size: 32px; margin-right: 20px;'></i></div><div><strong>Paina tästä lisätäksesi tiedostoja</strong></div></div>`,
      type: String,
    },
    acceptedFileTypes: {
      default:
        "application/pdf,image/*,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.oasis.opendocument.text,application/vnd.oasis.opendocument.spreadsheet,application/vnd.oasis.opendocument.presentation,text/plain,application/rtf,application/zip,text/csv",
      type: String,
    },
    multiple: {
      default: true,
      type: Boolean,
    },
    maxFiles: {
      default: 20,
      type: Number,
    },
  },
  data() {
    return {
      files: [],
    };
  },
  computed: {
    hasFiles() {
      return this.files?.length > 0;
    },
  },
  methods: {
    handleFilePondInit() {},
    async getRawFiles() {
      const files = [];
      if (this.files) {
        for (let file of this.files) {
          files.push(file.file);
        }
      }
      return files;
    },
    async getFiles() {
      const files = [];
      if (this.files) {
        for (let file of this.files) {
          try {
            let b64file;
            if (file.fileType.startsWith("image/")) {
              b64file = await this.blobToBase64(
                await readAndCompressImage(file.file, {
                  quality: 0.7,
                  maxWidth: 1920,
                  maxHeight: 1080,
                  autoRotate: true,
                  debug: true,
                })
              );
            } else {
              b64file = await this.blobToBase64(file.file);
            }
            files.push({
              content: b64file,
              filename: file.filename,
              fileType: file.fileType,
              fileSize: file.fileSize,
              filenameWithoutExtension: file.filenameWithoutExtension,
              fileExtension: file.fileExtension,
            });
          } catch (e) {
            console.error(e);
          }
        }
      }
      return files;
    },
    async reset() {
      this.files = [];
      this.$refs.pond.removeFiles();
    },
    async updateFiles(_files) {
      const files = [];
      for (let file of _files) {
        try {
          files.push(file);
        } catch (e) {
          console.error(e);
        }
      }
      this.$emit("update:files", files);
      this.files = files;
    },
    blobToBase64(blob) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },
  },
};
</script>
