<template>
  <basic-page title="Lainaus">
    <v-row class="fill-height">
      <v-col cols="12" lg="12" xl="12">
        <v-row class="mb-5">
          <v-col cols="12" md="12">
            <v-card>
              <v-card-title>
                Lainat
                <v-spacer />
                <v-switch
                  v-model="showOld"
                  class="ml-5 mt-n1"
                  dense
                  hide-details
                  inset
                  prepend-icon="mdi mdi-eye"
                  title="Näytä vanhat ilmoitukset"
                >
                </v-switch
              ></v-card-title>
              <v-divider />
              <v-card-text>
                <v-data-table
                  :items="filteredLoans"
                  :headers="headers"
                  :loading="loadingLoans"
                >
                  <template v-slot:item.equipment="{ item }">
                    <router-link
                      :to="{
                        name: 'KalustoById',
                        params: { id: item.equipment.id },
                      }"
                    >
                      {{ item.equipment.id }} -
                      {{ item.equipment.name }}
                    </router-link>
                    <div>
                      {{ item.equipment.location }}
                    </div>
                  </template>
                  <template v-slot:item.startDate="{ item }">
                    {{
                      $formatDateAndHourMinute(
                        item.startDate + " " + item.startTime
                      )
                    }}
                  </template>
                  <template v-slot:item.endDate="{ item }">
                    {{
                      $formatDateAndHourMinute(
                        item.endDate + " " + item.endTime
                      )
                    }}
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      v-if="
                        item.createdBy.sub === $userDBContext.sub ||
                        $isSuperAdmin
                      "
                      icon
                      @click="openLoan(item)"
                      :disabled="loadingEquipments"
                    >
                      <v-icon>mdi mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title>Tee lainaus</v-card-title>
              <v-divider />
              <v-progress-linear indeterminate v-if="loadingEquipments" />
              <v-card-text v-if="!loan.equipment && !loadingEquipments">
                <v-text-field
                  v-model="search"
                  label="Hae kalustoa"
                  outlined
                  dense
                  clearable
                />
                <p class="text-subtitle-2">Valitse lainattava kalusto alta</p>
                <v-list>
                  <v-list-item
                    v-for="e in filteredFlattenedEquipmentTreeData"
                    :key="e.id"
                    @click="loan.equipment = e"
                  >
                    <v-list-item-action
                      style="min-width: 100px; min-height: 70px"
                    >
                      <v-img
                        v-if="e.pictureThumbnail"
                        :src="e.pictureThumbnail"
                        max-height="100"
                        max-width="100"
                        width="100"
                        contain
                      />
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title class="text-wrap">
                        {{ e.id }} - {{ e.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="text-wrap">
                        {{ e._fullTree2 }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
              <v-card-text v-if="loan.equipment">
                <v-row>
                  <v-col cols="12">
                    <div class="text-subtitle-1 font-weight-bold">
                      Lainattava kalusto
                    </div>
                    <div class="mt-2">
                      <v-row no-gutters align-content="center">
                        <v-list-item v-if="loan.equipment">
                          <v-list-item-action
                            style="min-width: 100px; min-height: 70px"
                          >
                            <v-img
                              v-if="loan.equipment.pictureThumbnail"
                              :src="loan.equipment.pictureThumbnail"
                              max-height="100"
                              max-width="100"
                              width="100"
                              contain
                            />
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title class="text-wrap">
                              {{ loan.equipment.id }} -
                              {{ loan.equipment.name }}
                              <v-btn
                                icon
                                @click="loan.equipment = null"
                                class="ml-5"
                              >
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                            </v-list-item-title>
                            <v-list-item-subtitle class="text-wrap">
                              {{ loan.equipment._fullTree2 }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <modal-picker
                      v-model="loan.startDate"
                      label="Lainan aloituspvm"
                      required
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <modal-picker
                      v-model="loan.startTime"
                      label="Aloitusaika"
                      type="time"
                      required
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <modal-picker
                      v-model="loan.endDate"
                      label="Palautuspvm"
                      required
                    >
                      <template v-slot:append>
                        <v-btn
                          icon
                          @click="loan.endDate = loan.startDate"
                          :disabled="!loan.startDate"
                          class="mt-n2"
                        >
                          <v-icon>mdi mdi-calendar-today</v-icon>
                        </v-btn>
                      </template>
                    </modal-picker>
                  </v-col>
                  <v-col cols="12" md="6">
                    <modal-picker
                      v-model="loan.endTime"
                      label="Palautusaika"
                      type="time"
                      required
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="loan.loaner"
                      label="Lainaaja"
                      outlined
                      hide-details
                      :rules="[(v) => !!v || 'Lainaaja vaaditaan']"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-select
                      :items="permitIssuers"
                      label="Luvan myöntäjä"
                      v-model="loan.permitIssuer"
                      outlined
                      hide-details
                      :rules="[(v) => !!v || 'Luvan myöntäjä vaaditaan']"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-textarea
                      v-model="loan.info"
                      label="Lisätiedot (ei pakollinen)"
                      auto-grow
                      outlined
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider v-if="loan.equipment" />
              <v-card-actions v-if="loan.equipment">
                <v-btn
                  color="error"
                  v-if="loan.id"
                  @click="deleteLoan"
                  :loading="loadingDelete"
                  >Poista</v-btn
                >

                <v-spacer />
                <v-btn @click="resetLoan" text>Peruuta</v-btn>
                <v-btn color="primary" @click="saveLoan" :loading="loadingSave"
                  >Tallenna</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </basic-page>
</template>

<script>
import BasicPage from "@/components/BasicPage";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { firestore as db } from "@/plugins/firebase.app";
import { debounce, orderBy } from "lodash";
import ModalPicker from "@/components/ModalPicker.vue";
import dayjs from "dayjs";

const locationsTable = "kalusto_sijainnit";
const equipmentTable = "kalusto";
const equipmentTypesTable = "kalusto_tyypit";
const loanTable = "kalusto_lainat";

export default {
  name: "Lainaus",
  components: { ModalPicker, BasicPage },
  data: () => {
    return {
      loading: false,
      loadingEquipments: false,
      loadingSave: false,
      loadingDelete: false,
      loadingLoans: false,
      locations: [],
      equipments: [],
      loans: [],
      equipmentTypes: [],
      showOld: false,
      search: "",
      dSearch: "",
      debouncedSearch: null,
      permitIssuers: [
        { text: "Jarno Joensuu", value: "Jarno Joensuu" },
        { text: "Kai Heikkilä", value: "Kai Heikkilä" },
        { text: "Joni Leino", value: "Joni Leino" },
      ],

      loan: {
        id: null,
        startDate: null,
        endDate: null,
        startTime: null,
        endTime: null,
        equipment: null,
        loaner: null,
        permitIssuer: null,
        info: null,
        createdBy: null,
        created: null,
        updated: null,
        deletedAt: null,
        deleted: false,
      },
    };
  },
  watch: {
    search() {
      this.debouncedSearch();
    },
  },
  computed: {
    headers() {
      return [
        { text: "Kalusto", value: "equipment" },
        { text: "Laina-aika", value: "startDate" },
        { text: "Palautusaika", value: "endDate" },
        { text: "Lainaaja", value: "loaner" },
        { text: "Luvan myöntäjä", value: "permitIssuer" },
        { text: "Lisätiedot", value: "info" },
        { text: "", value: "actions" },
      ];
    },
    treeData() {
      // Step 1: Create a map of all locations with an initial structure
      const map = new Map(
        this.locations.map((loc) => [
          loc.id,
          { ...loc, children: [], _fullTree: "" },
        ])
      );

      // Step 2: Assign children to their parents
      map.forEach((node) => {
        if (node.parent) {
          const parent = map.get(node.parent);
          if (parent) {
            parent.children.push(node);
          }
        }
      });

      // Step 3: Build the tree with _fullTree calculated dynamically
      const tree = [];
      const calculateFullTree = (node, parentFullTree = "") => {
        const fullTree = parentFullTree
          ? `${parentFullTree} -> ${node.name}`
          : node.name;
        return {
          ...node,
          _name: node.name,
          _fullTree: fullTree,
          icon: "mdi mdi-map-marker-outline",
          children: [
            ...orderBy(
              node.children.map((child) => calculateFullTree(child, fullTree)),
              ["_name"],
              ["asc"]
            ),
            ...orderBy(
              this.equipments
                .filter((e) => e.locationId === node.id)
                .map((e) => ({
                  ...e,
                  _name: [e.name, e.id].join(" - "),
                  _fullTree: `${fullTree} -> ${e.name}`,
                  _fullTree2: `${fullTree}`,
                  _isEquipment: true,
                })),
              ["_name"],
              ["asc"]
            ),
          ],
        };
      };

      map.forEach((node) => {
        if (!node.parent) {
          tree.push(calculateFullTree(node)); // Root nodes start the recursion
        }
      });

      return tree;
    },
    locationTreeData() {
      // only list treedata where _isEquipment is not true
      const filterNonEquipment = (node) => {
        if (node._isEquipment) {
          return null;
        }
        return {
          ...node,
          children: node.children
            ?.filter((n) => !n._isEquipment)
            .map(filterNonEquipment)
            .filter(Boolean),
        };
      };
      return this.treeData.map(filterNonEquipment).filter(Boolean);
    },
    flattenedEquipmentTreeData() {
      const flatten = (node) => {
        return [
          { ...node, children: undefined },
          ...(node.children?.flatMap(flatten) || []),
        ];
      };
      return this.treeData
        .flatMap(flatten)
        .filter(Boolean)
        .filter((e) => e._isEquipment);
    },
    filteredFlattenedEquipmentTreeData() {
      if (!this.dSearch) {
        return this.flattenedEquipmentTreeData.filter((e) => e.loanable);
      }
      return this.flattenedEquipmentTreeData
        .filter((e) => e.loanable)
        .filter((e) => {
          return this.dSearch
            .toLowerCase()
            .split(" ")
            .filter(Boolean)
            .some((s) => {
              return [e.name, e.id].join(" ").toLowerCase().includes(s);
            });
        });
    },
    selectedEquipment() {
      if (!this.loan.equipment) {
        return null;
      }
      return this.flattenedEquipmentTreeData.find(
        (e) => e.id === this.loan.equipment.id
      );
    },
    filteredLoans() {
      const now = dayjs().startOf("day");
      let loans = orderBy(
        this.loans
          .map((l) => {
            return {
              ...l,
              _startTime: dayjs(l.startDate + " " + l.startTime).unix(),
              _endTime: dayjs(l.endDate + " " + l.endTime).unix(),
            };
          })
          .filter((l) => this.showOld || now.isBefore(dayjs.unix(l._endTime))),
        ["_startTime"],
        ["asc"]
      );

      return loans.map((l) => {
        return {
          ...l,
          equipment:
            this.equipments.find((e) => e.id === l.equipment.id) || l.equipment,
        };
      });
    },
  },
  methods: {
    async fetchLoans() {
      this.loadingLoans = true;
      try {
        const snapshot = await getDocs(
          query(collection(db, loanTable), where("deleted", "==", false))
        );
        this.loans = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
      } catch (e) {
        console.error(e);
        this.$toast("Lainojen haku epäonnistui", { type: "error" });
      }
      this.loadingLoans = false;
    },
    async fetchLocations() {
      const snapshot = await getDocs(
        query(collection(db, locationsTable), where("deleted", "==", false))
      );
      this.locations = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    },
    async fetchEquipments() {
      let equipmentsSet = false;
      if (this.$store.state?.equipments?.length > 0) {
        this.equipments = this.$store.state.equipments;
        equipmentsSet = true;
      }
      if (!equipmentsSet) {
        this.loadingEquipments = true;
      }
      try {
        const snapshot = await getDocs(
          query(collection(db, equipmentTable), where("deleted", "==", false))
        );
        this.equipments = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          icon: "mdi-toy-brick",
        }));
        await this.$store.dispatch("setCachedEquipments", this.equipments);
      } catch (e) {
        console.error(e);
        this.$toast("Kaluston haku epäonnistui", { type: "error" });
      }

      this.loadingEquipments = false;
    },
    // Fetch equipment types for autocomplete
    async fetchEquipmentTypes() {
      const snapshot = await getDocs(collection(db, equipmentTypesTable));
      this.equipmentTypes = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    },
    async openLoan(loan) {
      this.loan = {
        ...loan,
        equipment: this.flattenedEquipmentTreeData.find(
          (e) => e.id === loan.equipment?.id
        ),
      };
    },
    async saveLoan() {
      // eslint-disable-next-line no-undef
      const loan = structuredClone({ ...this.loan });

      const start = dayjs(loan.startDate + " " + loan.startTime);
      const end = dayjs(loan.endDate + " " + loan.endTime);

      if (!loan.equipment) {
        this.$toast("Valitse lainattava kalusto", { type: "error" });
        return;
      }
      if (
        !loan.startDate ||
        !loan.endDate ||
        !loan.startTime ||
        !loan.endTime ||
        !loan.equipment ||
        !loan.permitIssuer
      ) {
        if (!loan.startDate) {
          this.$toast("Valitse lainan aloituspäivämäärä.", { type: "error" });
        }
        if (!loan.endDate) {
          this.$toast("Valitse lainan palautuspäivämäärä", { type: "error" });
        }
        if (!loan.startTime) {
          this.$toast("Valitse lainan aloitusaika", { type: "error" });
        }
        if (!loan.endTime) {
          this.$toast("Valitse lainan palautusaika", { type: "error" });
        }
        if (!loan.equipment) {
          this.$toast("Valitse lainattava kalusto", { type: "error" });
        }
        if (!loan.permitIssuer) {
          this.$toast("Valitse luvan myöntäjä", { type: "error" });
        }
        //this.$toast("Täytä kaikki kentät.", { type: "error" });
        return;
      }

      if (loan.startDate > loan.endDate) {
        this.$toast(
          "Lainan aloituspäivämäärä ei voi olla suurempi kuin palautuspäivämäärä",
          { type: "error" }
        );
        return;
      }
      if (start.isAfter(end)) {
        this.$toast(
          "Lainan aloitusaika ei voi olla palautusaikaa myöhäisempi",
          { type: "error" }
        );
        return;
      }

      if (
        !loan.id && // Ideana että pystyy silti muokkaamaan vanhaa
        (start.isBefore(dayjs().startOf("day")) ||
          end.isBefore(dayjs().startOf("day")))
      ) {
        this.$toast("Laina ei voi olla menneisyydessä", {
          type: "error",
        });
        return;
      }

      if (
        this.loans.some((l) => {
          const lstart = dayjs(l.startDate + " " + l.startTime);
          const lend = dayjs(l.endDate + " " + l.endTime);
          if (
            l.id !== loan.id &&
            l.equipment.id === loan.equipment.id &&
            ((start.isBefore(lend) && start.isAfter(lstart)) ||
              (end.isBefore(lend) && end.isAfter(lstart)) ||
              (start.isBefore(lstart) && end.isAfter(lend)))
          ) {
            return true;
          }
        })
      ) {
        this.$toast("Kalusto on jo lainassa valitulla aikavälillä", {
          type: "error",
        });
        return;
      }

      this.loadingSave = true;
      try {
        // eslint-disable-next-line no-undef
        const loan = structuredClone({ ...this.loan });
        loan.createdBy = this.$userDBContext;
        loan.created = dayjs().toISOString();
        loan.deleted = false;
        loan.equipment = {
          id: loan.equipment.id,
          name: loan.equipment.name || "",
          locationId: loan.equipment.locationId || "",
          location: loan.equipment.location || "",
          _fullTree: loan.equipment._fullTree || "",
          _fullTree2: loan.equipment._fullTree2 || "",
        };

        console.log("Saving loan", this.loan);
        const existsInDatabase = loan.id
          ? (await getDoc(doc(db, loanTable, loan.id))).exists()
          : false;
        if (!existsInDatabase) {
          console.log("Adding new loan", loan);
          await addDoc(collection(db, loanTable), loan);
        } else {
          loan.updated = dayjs().toISOString();
          await updateDoc(doc(db, loanTable, loan.id), {
            ...loan,
            deleted: false,
          });
        }
        this.$toast("Laina tallennettu", { type: "success" });

        this.resetLoan();
        await this.fetchLoans();
      } catch (e) {
        console.error(e);
        this.$toast("Lainan tallennus epäonnistui", { type: "error" });
      }
      this.loadingSave = false;
    },
    async deleteLoan() {
      if (!this.loan.id) {
        return;
      }
      this.loadingDelete = true;
      try {
        const loan = { ...this.loan, equipment: null };
        loan.deleted = true;
        loan.deletedAt = dayjs().toISOString();
        await updateDoc(doc(db, loanTable, loan.id), loan);
        this.resetLoan();
        await this.fetchLoans();
        0;
      } catch (e) {
        console.error(e);
        this.$toast("Lainan poisto epäonnistui", { type: "error" });
      }
      this.loadingDelete = false;
    },
    resetLoan() {
      this.loan = {
        id: null,
        startDate: null,
        endDate: null,
        startTime: null,
        endTime: null,
        equipment: null,
        loaner: this.$store.state.user?.displayName,
        permitIssuer: null,
        info: null,
        createdBy: this.$userDBContext,
        created: null,
        updated: null,
        deletedAt: null,
        deleted: false,
      };
    },
  },
  mounted() {
    this.fetchLocations();
    this.fetchEquipmentTypes();
    this.fetchEquipments();
    this.fetchLoans();
    if (this.loan) {
      this.resetLoan();
    }
    this.debouncedSearch = debounce(() => (this.dSearch = this.search), 500);
  },
};
</script>

<style scoped lang="scss"></style>
