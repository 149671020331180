<template>
  <v-main>
    <v-container class="fill-height pa-0 ma-0" fluid>
      <v-col
        v-if="$vuetify.breakpoint.lgAndUp"
        class="fill-height vpk-background d-flex justify-center align-center"
        cols="6"
      >
        <h1 class="white--text">Noormarkun VPK App</h1>
      </v-col>
      <v-col
        class="d-flex justify-center align-center flex-column"
        cols="12"
        lg="6"
      >
        <v-col class="d-flex justify-center align-center flex-column" cols="12">
          <v-img
            contain
            height="150"
            max-width="85%"
            src="https://noormarkunvpk.fi/wp-content/uploads/2017/03/logo.png"
          />
          <h1 v-if="$vuetify.breakpoint.mdAndDown">Noormarkun VPK App</h1>
        </v-col>
        <v-col v-if="mode === 'login'" cols="12" lg="6">
          <v-form ref="loginForm" @submit="login">
            <v-card>
              <v-card-title>Kirjaudu</v-card-title>
              <v-divider />
              <v-card-text>
                <v-text-field
                  v-model="email"
                  :rules="[$rules.email]"
                  label="Sähköpostiosoite"
                  outlined
                />
                <v-text-field
                  v-model="password"
                  :rules="[$rules.required]"
                  label="Salasana"
                  outlined
                  type="password"
                />
              </v-card-text>

              <v-card-actions class="d-flex flex-column">
                <v-btn
                  :loading="loadingLogin"
                  block
                  color="primary"
                  type="submit"
                  >Kirjaudu
                </v-btn>
                <v-divider />
                <div class="mt-7">
                  <span
                    ><small>
                      <a
                        href="#"
                        @click="
                          () => {
                            this.mode = 'resetPasswordP1';
                          }
                        "
                        >Unohtunut salasana?</a
                      ></small
                    ></span
                  >
                </div>
                <div class="mt-2">
                  <span
                    ><small
                      >Ei vielä tunnusta?
                      <a href="#" @click="toggleMode">Rekisteröidy</a></small
                    ></span
                  >
                </div>
                <div
                  class="d-flex justify-center flex-column align-center mt-3"
                  style="width: 100%"
                >
                  <v-divider class="mb-3" style="width: 100%"></v-divider>
                  <v-btn v-if="false" icon @click="loginGoogle">
                    <v-icon color="primary">mdi mdi-google</v-icon>
                  </v-btn>
                </div>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
        <v-col v-if="mode === 'register'" cols="12" lg="6" md="12">
          <v-form ref="registerForm" @submit="register">
            <v-card>
              <v-card-title>
                <v-btn icon @click="toggleMode">
                  <v-icon>mdi mdi-arrow-left</v-icon> </v-btn
                >&nbsp; Rekisteröidy
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-text-field
                  v-model="fullname"
                  :rules="[$rules.required]"
                  label="Koko nimesi"
                  outlined
                  placeholder="esim. Esko Palopoika"
                />
                <v-text-field
                  v-model="email"
                  :rules="[$rules.email]"
                  label="Sähköpostiosoite"
                  outlined
                />
                <v-text-field
                  v-model="password"
                  :rules="[$rules.required]"
                  label="Salasana"
                  outlined
                  type="password"
                />
              </v-card-text>
              <v-card-actions>
                <v-btn
                  :loading="loadingRegister"
                  block
                  class="mb-7"
                  color="primary"
                  type="submit"
                >
                  Rekisteröidy
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
        <v-col v-if="mode === 'resetPasswordP1'" cols="12" lg="6" md="12">
          <v-form ref="forgotPasswordP1Form" @submit="resetPasswordP1">
            <v-card>
              <v-card-title>
                <v-btn icon @click="mode = 'login'">
                  <v-icon>mdi mdi-arrow-left</v-icon> </v-btn
                >&nbsp; Salasanan palautus
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-text-field
                  v-model="email"
                  :rules="[$rules.email]"
                  label="Sähköpostiosoite"
                  outlined
                />
              </v-card-text>
              <v-card-actions>
                <v-btn
                  :loading="loadingForgotPasswordP1"
                  block
                  class="mb-7"
                  color="primary"
                  type="submit"
                >
                  Palauta salasana
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
        <v-col v-if="mode === 'resetPassword'" cols="12" lg="6" md="12">
          <v-form ref="forgotPasswordP2Form" @submit="resetPassword">
            <v-card>
              <v-card-title>
                <v-btn icon @click="mode = 'login'">
                  <v-icon>mdi mdi-arrow-left</v-icon> </v-btn
                >&nbsp; Salasanan palautus
              </v-card-title>
              <v-divider />
              <v-card-text>
                <!--<v-text-field
                  v-model="email"
                  :rules="[$rules.email]"
                  label="Sähköpostiosoite"
                  outlined
                  readonly
                  disabled
                />-->
                <v-text-field
                  v-model="password"
                  :rules="[$rules.required]"
                  label="Uusi salasana"
                  outlined
                  type="password"
                />
                <v-text-field
                  v-model="password2"
                  :rules="[$rules.required]"
                  label="Uusi salasana uudestaan"
                  outlined
                  type="password"
                />
                <span class="caption"
                  >Salasanan pitää olla vähintään 8 merkkiä pitkä ja sisältää
                  vähintään yksi numero, erikoismerkki ja iso kirjain</span
                >
              </v-card-text>
              <v-card-actions>
                <v-btn
                  :loading="loadingForgotPasswordP2"
                  block
                  class="mb-7"
                  color="primary"
                  type="submit"
                >
                  Aseta salasana
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
        <v-col v-if="mode === 'passwordResetLinkSent'" cols="12" lg="6" md="12">
          <v-card>
            <v-card-title>
              <v-btn icon @click="mode = 'login'">
                <v-icon>mdi mdi-arrow-left</v-icon> </v-btn
              >&nbsp; Salasanan palautus
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-alert type="success">
                Salasanan palautuslinkki lähetetty sähköpostiisi. Ole hyvä ja
                tarkasta sähköpostisi.
              </v-alert>
            </v-card-text>
          </v-card>
        </v-col>
      </v-col>
    </v-container>
  </v-main>
</template>

<script>
//import firebaseApp from "@/plugins/firebase.app";

//import firebaseApp from "@/plugins/firebase.app";
import app, { auth, googleAuthProvider } from "@/plugins/firebase.app";
import {
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
} from "firebase/auth";
import { deleteToken, getMessaging } from "firebase/messaging";

export default {
  name: "Login",

  data() {
    return {
      fullname: "",
      email: "",
      password: "",
      password2: "",
      mode: "login",
      loadingRegister: false,
      loadingLogin: false,
      loadingForgotPasswordP1: false,
      loadingForgotPasswordP2: false,
    };
  },

  mounted() {
    if (this.$user) {
      this.$router.push({ name: "Ilmoitustaulu" });
    } else {
      if (this.$route.query.mode === "resetPassword") {
        this.mode = "resetPassword";
      }
    }
  },
  methods: {
    async updateUser(currentUser) {
      //await this.$store.dispatch("updateUser", { currentUser });
      await this.$store.dispatch("setNewLogin", { currentUser });
    },
    toggleMode() {
      if (this.mode === "login") {
        this.mode = "register";
      } else {
        this.mode = "login";
      }
    },
    async login(e) {
      e.preventDefault();
      if (!this.$refs.loginForm.validate()) {
        return;
      }
      this.loadingLogin = true;
      let userCredential;
      try {
        userCredential = await signInWithEmailAndPassword(
          auth,
          this.email?.trim(),
          this.password
        );
        this.password = "";
      } catch (error) {
        console.error(error, error.message, error.code);
        const errorCode = error.code;
        if (errorCode === "auth/user-not-found") {
          this.$toast(
            "Käyttäjää ei löytynyt. Ole hyvä ja tarkasta sähköpostiosoite tai rekisteröidy.",
            {
              type: "error",
            }
          );
        } else if (errorCode === "auth/wrong-password") {
          this.$toast("Salasana oli väärin", {
            type: "error",
          });
        } else if (errorCode === "auth/internal-error") {
          this.$toast("Sisäinen virhe " + error.message, {
            type: "error",
          });
        } else {
          this.$toast("Virhe " + error.message, {
            type: "error",
          });
        }
        this.loadingLogin = false;
        return;
      }

      const user = userCredential.user;
      this.password = "";
      await this.updateUser(user);
      this.loadingLogin = false;
    },
    async loginGoogle() {
      let result;
      try {
        result = await signInWithPopup(auth, googleAuthProvider);
      } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);

        console.error(error, credential);
        return;
      }
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      await this.updateUser(user);
      this.loadingLogin = false;
    },
    async resetPasswordP1(e) {
      e.preventDefault();
      if (!this.$refs.forgotPasswordP1Form.validate()) {
        return;
      }
      this.loadingForgotPasswordP1 = true;
      try {
        await this.resetPW();
        this.$toast("Salasanan palautuslinkki lähetetty sähköpostiisi.", {
          type: "success",
        });
        this.mode = "passwordResetLinkSent";
      } catch (error) {
        console.error(error);
        this.$toast("Virhe palautettaessa salasanaa: " + error.message, {
          type: "error",
        });
      }
      this.loadingForgotPasswordP1 = false;
    },
    async resetPW() {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, this.email);
    },
    async resetPassword(e) {
      e.preventDefault();
      const oobCode = this.$route.query.oobCode;
      const apiKey = this.$route.query.apiKey;

      if (!oobCode) {
        return this.$toast("Virhe palautettaessa salasanaa: oobCode puuttuu", {
          type: "error",
        });
      }
      if (!this.password) {
        return this.$toast("Salasana ei voi olla tyhjä", {
          type: "error",
        });
      }
      const auth = getAuth();

      // check that password has at least 8 characters and has at least one number and special character and one uppercase letter
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/;
      if (!regex.test(this.password)) {
        return this.$toast(
          "Salasanan pitää olla vähintään 8 merkkiä pitkä ja sisältää vähintään yksi numero, erikoismerkki ja iso kirjain",
          {
            type: "error",
          }
        );
      }
      if (this.password !== this.password2) {
        return this.$toast("Salasanat eivät täsmää", {
          type: "error",
        });
      }
      this.loadingForgotPasswordP2 = true;
      try {
        await confirmPasswordReset(auth, oobCode, this.password);
        this.$toast(
          "Salasana vaihdettu. Voit nyt kirjautua uudella salasanallasi.",
          {
            type: "success",
          }
        );
        try {
          const messaging = getMessaging(app);
          await deleteToken(messaging);
        } catch (e) {
          console.error(e);
        }
        this.mode = "login";
        this.password = "";
        this.password2 = "";
      } catch (e) {
        console.error(e);
        this.$toast("Virhe palautettaessa salasanaa: " + e.message, {
          type: "error",
        });
      }
      this.loadingForgotPasswordP2 = false;
    },
    async register(e) {
      e.preventDefault();
      if (!this.$refs.registerForm.validate()) {
        return;
      }
      this.loadingRegister = true;
      let userCredential;
      try {
        userCredential = await createUserWithEmailAndPassword(
          auth,
          this.email?.trim(),
          this.password
        );
      } catch (error) {
        console.error("Error registering", error.message, error);
        this.$toast("Virhe luodessa käyttäjätunnusta: " + error.message, {
          type: "error",
        });
        this.loadingRegister = false;
        return;
      }

      const user = userCredential.user;
      try {
        await updateProfile(user, {
          displayName: this.fullname,
        });
      } catch (error) {
        console.error("Error updating profile", error.message, error);
      }
      this.$toast("Rekisteröityminen onnistui.", {
        type: "success",
      });
      this.mode = "login";
      this.password = null;
      await this.updateUser(user);
      this.loadingRegister = false;
    },
  },
  created() {},
};
</script>

<style scoped>
.vpk-background {
  background: var(--v-primary-base);
}
</style>
